<template>
  <BModal
    id="modal-confirmation"
    ref="modal-confirmation"
    hide-footer
    size="md"
    hide-header
    centered
    hide-header-close
  >
    <div
      class="p-[32px]"
    >
      <div>
        <div class="flex justify-center">
          <img
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
            alt="Komerce"
            class="h-[100px]"
          >
        </div>
        <div class="text-[#333333] mt-[32px] text-center font-bold text-[24px]">
          Konfirmasi Pengajuan
        </div>
        <div
          class="text-[#626262] text-center leading-[24px] text-[16px] mt-[8px]"
        >
          Apakah kamu sudah pastikan bahwa user ini sudah dieksekusi untuk mendapat akses Akun WL KomAds?
        </div>
      </div>
      <div class="flex gap-[10px] mt-[32px]">
        <BButton
          variant="outline-primary"
          class="w-full"
          @click="$bvModal.hide('modal-confirmation')"
        >
          Kembali
        </BButton>
        <BButton
          variant="primary"
          class="w-full"
          @click="submitModal"
        >
          Approve
        </BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ModalConfirmation',
  props: {
    activeModalId: {
      type: Number,
      default: null,
    },
  },

  methods: {

    async submitModal() {
      const url = `auth/api/v1/whitelist-account/${this.activeModalId}/approve`

      try {
        this.loadingTable = true
        await newAxiosIns.request(
          {
            method: 'PUT',
            url,
          },
        )
        this.$bvModal.hide('modal-confirmation')

        this.$toast(
          {
            component: ToastificationContentVue,
            props: {
              title: 'Berhasil',
              icon: 'CheckIcon',
              text: 'Status pengajuan berhasil di aktifkan',
              variant: 'success',
            },
          },
          { timeout: 3000 },
        )
        this.$emit('refetchListWhitelistData')
      } catch (error) {
        this.$toast_error({ message: 'Gagal Memsubmit data' })
        this.$bvModal.hide('modal-confirmation')
      }
    },
  },
}
</script>

<style scoped>

</style>
