const configColumn = [
  {
    key: 'date',
    label: 'Tanggal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdStyle: {
      textTransform: 'capitalize',
      minWidth: '150px',
      textAlign: 'left',
    },
    tdClass: 'text-[#828282]',
  },
  {
    key: 'name',
    label: 'Nama Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdStyle: {
      textTransform: 'capitalize',
      minWidth: '150px',
      textAlign: 'left',
    },
    tdClass: 'text-[#333333]',
  },
  {
    key: 'number_phone',
    label: 'Nomor Handphone',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdClass: 'text-[#333333]',
    tdStyle: {
      textTransform: 'capitalize',
      minWidth: '150px',
      textAlign: 'left',
    },
  },
  {
    key: 'action',
    label: '',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdStyle: {
      textTransform: 'capitalize',
      minWidth: '150px',
      textAlign: 'left',
    },
  },
]

export default configColumn
